
html {width: 100%;margin: 0;padding: 0;border: 0;font-family: Roboto, sans-serif;}
body {width: 100%;position: relative;margin: 0;}
#root {width: 100%;overflow-y: auto;background: white;}
#page {display: flex;flex-direction: column;}
#root::-webkit-scrollbar-track {border-radius: 4px;}
#root::-webkit-scrollbar {width: 10px;}
#root::-webkit-scrollbar-thumb {border-radius: 4px;background: #f0f2f5;}
#root:hover::-webkit-scrollbar-thumb {background: #5793ce;}

::-webkit-scrollbar{width:10px;}
::-webkit-scrollbar-button{}
::-webkit-scrollbar-track{border-width:0;}
::-webkit-scrollbar-track:hover{border:solid 1px #CCCCCC;background-color:#CCCCCC;}
::-webkit-scrollbar-track-piece{background-color:#E8E8E8;}
::-webkit-scrollbar-thumb{border-width:1px 1px 1px 1px;border-color:#777777;background-color:#AAAAAA;}
::-webkit-scrollbar-thumb:hover{border-width:1px 1px 1px 1px;border-color:#555555;background-color:#5793ce;}
::-webkit-scrollbar-corner{}
::-webkit-resizer{}

.header__menu > a:active {opacity: 1.00;}
.header__menu > a:hover {opacity: 0.75;}
.header__menu > a:focus {opacity: 0.55;}
.banner__left-button:active {opacity: 1.00;}
.banner__left-button:hover {opacity: 0.75;}
.banner__left-button:focus {opacity: 0.55;}

a:active, div:active, a:focus, div:focus, a:focus-visible, div:focus-visible {outline: none;}


.DropDown               {position: relative;display: flex;font-size: calc(1.5vw + 5px);}
.DropDown__active       {display: flex;justify-content: center;align-items: center;cursor: pointer;}
.DropDown__active-name  {padding: 10px 20px; color: #04ADBF;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}

.DropDown__active:active, .DropDown__active:hover, .DropDown__active:focus, .DropDown__active:focus-visible {filter: brightness(0.5);}

.DropDown__active-arrow {height: 10px;padding-top: 3px;margin-left: 5px;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.DropDown__list         {position: absolute;top: calc(1.5vw + 29px);left: 0;display: flex;flex-direction: column;background-color: white;}
.DropDown__list-item    {padding: 10px 20px; color: #A1A1A1;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;cursor: pointer;}
.DropDown__list-item:active, .DropDown__list-item:hover, .DropDown__list-item:focus, .DropDown__list-item:focus-visible {filter: brightness(0.5);}

@media (max-width : 812px) {
  #root::-webkit-scrollbar-thumb {background: #5793ce;}
  #root::-webkit-scrollbar {width: 0;}
}

@media (max-width : 500px) {
  .DropDown {font-size: calc(3vw + 5px);height: calc(3vw + 5px);}
  .DropDown__list {top: calc(3vw + 6px);}
}


.header {display: flex;justify-content: space-between;align-items: center;flex: 0 0 8vw;height: 100%;width: calc(100% - 40px);padding: 0 20px;max-width: 85vw;margin: 0 auto;background: white;z-index: 2;}
.header__img {flex: 0 0 6vw;height: 6vw;margin-right: 40px;}
.header__menu {flex: 1 1 auto;justify-content: space-around;align-items: center;display: flex;}
.header__menuMobile {position: absolute;top: 39px;left: -1vw;background-color: white;width: calc(100% + 1vw);flex-direction: column;align-items: center;justify-content: center;}
.header__menu > a, .header__menuMobile > a {text-decoration: none;font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: calc(1.5vw + 5px);color: #30527B;}
.header__menuMobile > a {margin: 10px;}
.header__choice {margin-left: 40px;display: flex;flex: 0 1 auto;}
.header__choice-language {flex: 0 0 auto;display: flex;align-items: center;justify-content: center;}
.header__languageMobile {flex: 0 0 auto;display: none;align-items: center;justify-content: center;}
.header__choice-con {flex: 0 0 40px;}
.header__choice-icon > img {cursor: pointer;height: 30px;width: 30px;margin-left: 30px;margin-top: 5px;}
.iconMenu {display: none;}
.iconCross {display: none;}
.content {flex: 1 1 auto;display: flex;flex-direction: column;}
.content__item {height: calc(100% - 68px);padding: 34px 0;}
.content__item_backgroundBlue {background: #B3E0FF;}
.content__item_backgroundGray {background: #F4F4F4;}
.content__item .banner {flex-direction: row;}
.banner__left {display: flex;flex-direction: column;justify-content: center;z-index: 1;flex: 1 1 auto;max-width: 50%;min-width: 39%;}
.banner__right {flex: 1 1 50%;background-size: contain;background-position: right bottom;background-repeat: no-repeat;height: 35vw;display: flex;flex-direction: column;justify-content: flex-end;align-items: center;}
.banner__right > .banner__left-button {display: none;}
.banner__left-text {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(1.1vw + 9px);color: #FFFFFF;margin-top: 50px;}
.banner__left-button {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: 1.9vw;color: #30527B;border: 1px solid #30527B;padding: 10px 25px;width: min-content;border-radius: 6px;margin-top: 50px;text-decoration: none;white-space: nowrap;}
.content__item-subItem {display: flex;flex-direction: column;width: calc(100% - 80px);padding: 0 40px;max-width: 85vw;margin: 0 auto;}
#whatOffer > .content__item-subItem > div:not(:first-child), #partners > .content__item-subItem > div:not(:first-child) {margin-top: calc(2.5vw + 10px);}
#solution > .content__item-subItem > div:not(:first-child):not(:last-child) {margin-top: calc(2.5vw + 10px);}
.icons {display: flex;width: 100%;justify-content: space-around;align-items: baseline;}
.icons__item {margin-top: calc(2.5vw + 10px);display: flex;flex-direction: column;align-items: center;justify-content: center;}
.arrow {width: 7vw;}
.icons__item_max-width {max-width: 11vw;}
.icons__item_max-width > .icons__item-icon {margin-bottom: calc(1.3vw + 9px);height: 6vw;width: 6vw;}
.icons__item_max-width2 {max-width: 24vw;}
.icons__item_max-width2 > .icons__item-icon {margin-bottom: calc(1.3vw + 9px);height: 11vw;width: 11vw;}
.icons__item-icon {margin-bottom: calc(1.3vw + 9px);}
.icons__item-text {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(1.1vw + 9px);line-height: calc(1.4vw + 9px);color: #6C6C6C;text-align: center;}
.icons__item_max-width2:not(:first-child) .icons__item-text {text-align: center;}
.icons__item-text_center {text-align: center;}
.icons__item-text2 {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(1.5vw + 9px);line-height: calc(1.9vw + 9px);color: #2F444E;text-align: center;}
.title {font-family: Roboto, sans-serif;font-style: normal;font-weight: bold;font-size: calc(1.9vw + 9px);line-height: calc(2.2vw + 9px);color: #30527B;}
.text {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(0.8vw + 9px);line-height: calc(1.2vw + 9px);color: #3D3D3D;}
.title2 {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(1.4vw + 9px);line-height: calc(1.6vw + 9px);font-feature-settings: 'ss01' on;color: #399CBC;}
.gridPartners {display: grid;grid-template-columns: repeat(auto-fill, 280px);grid-row-gap: 1em;grid-column-gap: 1em;justify-content: center;}
.gridPartners__item {width: 200px;height: 120px;border-radius: 10px;border: 1px solid #D5D5D5;background-color: white;display: flex;justify-content: center;align-items: center;padding: 20px;}
.gridPartners__item > img {max-width: 100%;max-height: 100%;}
.gridPartnersEng {display: grid;grid-template-columns: repeat(auto-fill, 360px);grid-row-gap: 1em;grid-column-gap: 1em;justify-content: center;}
.gridPartnersEng__item {width: 290px;height: 140px;border-radius: 10px;border: 1px solid #D5D5D5;background-color: white;display: flex;justify-content: center;align-items: center;padding: 20px;}
.gridPartnersEng__item > img {max-width: 100%;max-height: 100%;}
.gridText {display: grid;grid-template-columns: 1fr 1fr;grid-row-gap: 1em;}
.gridText__item {display: flex;align-items: center;}
.point {margin-right: 10px;width: 1vw;height: 1vw;border-radius: 50%;background-color: #04ADBF;}
#benefit > .content__item-subItem{background-position: right 11% bottom 17%;background-repeat: no-repeat;background-size: 28%;}
#benefit > .content__item-subItem > div {margin-bottom: calc(1.6vw + 10px);}
.benefitBanner {display: flex;width: calc(100% - 40px);padding: 0 20px;max-width: 85vw;margin: 0 auto;font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(1.1vw + 9px);line-height: calc(1.5vw + 9px);}
.benefitBanner__item {padding: 40px;display: flex;flex-direction: column;flex: 1 1 50%;background-color: white;border-radius: 20px;}
.benefitBanner__item:not(:first-child) {margin-left: calc(1.2vw + 9px);}
.benefitBanner__item > img {margin-bottom: calc(1.1vw + 9px);height: 6vw;}
.benefitBanner__item > div > div {display: inline-block;}
.benefitBanner__item-text {color: #3D3D3D;}
.benefitBanner__item-text2 {margin: 1.2vw 0;color: #3D3D3D;}
#marketing > .content__item-subItem > div:first-child {margin-bottom: calc(2.7vw + 10px);}
#marketing > .content__item-subItem > div {margin-bottom: calc(1.6vw + 10px);}
.marketingBanner {display: flex;width: 100%;justify-content: space-between;align-items: center;}
.marketingBanner__left {flex: 1 1 auto;border-left: 6px solid #04ADBF;padding-left: 40px;}
.marketingBanner__left-title {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(2vw + 9px);line-height: calc(2.3vw + 9px);text-transform: uppercase;color: #30527B;margin-bottom: 2vw;}
.marketingBanner__left-text {font-family: Roboto, sans-serif;font-style: normal;font-weight: normal;font-size: calc(1.4vw + 9px);line-height: calc(1.7vw + 9px);color: #399CBC;}
.marketingBanner__right {flex: 1 1 auto;position: relative;display: flex;align-items: center;}
.marketingBanner__right-circleCenter {display: flex;align-items: center;justify-content: center;border-radius: 50%;border: 2px dashed #A1A1A1;box-sizing: border-box;width: 25.5vw;height: 25.5vw;}
.circleBigInner {display: flex;align-items: center;justify-content: center;border-radius: 50%;background: #30527B;box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);flex: 0 1 24vw;height: 24vw;}
.marketingBanner__right-circleLeft {display: flex;align-items: center;justify-content: center;border-radius: 50%;background: #04ADBF;width: 15vw;height: 15vw;position: relative;left: 30px;}
.marketingBanner__right-circleRight {display: flex;align-items: center;justify-content: center;border-radius: 50%;background: #B3E0FF;width: 15vw;height: 15vw;position: relative;right: 30px;}
.textBig {font-family: Roboto, sans-serif;font-style: normal;font-weight: bold;font-size: calc(2vw + 5px);line-height: calc(2.6vw + 9px);text-align: center;color: #FFFFFF;}
.textMedium {font-family: Roboto, sans-serif;font-style: normal;font-weight: bold;font-size: calc(1.4vw + 6px);line-height: calc(1.9vw + 9px);text-align: center;color: #FFFFFF;}
.textSmall {font-family: Roboto, sans-serif;font-style: normal;font-weight: bold;font-size: calc(1.4vw + 3px);line-height: calc(1.6vw + 4px);text-align: center;color: #30527B;}
.footer {flex: 0 0 15vw;flex-direction: column;justify-content: center;display: flex;height: 100%;background-color: #E5E5E5;}
.footer__title {font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: calc(1.4vw + 9px);line-height: calc(1.9vw + 9px);color: #30527B;width: calc(100% - 40px);padding: 0 20px;max-width: 85vw;margin: 0 auto;}
.footer__description {font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: calc(0.8vw + 9px);color: #3D3D3D;width: calc(100% - 40px);padding: 0 20px;max-width: 85vw;margin: 0 auto;}
.footer__inn {font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: calc(0.8vw + 9px);color: #3D3D3D;width: calc(100% - 40px);padding: 0 20px;max-width: 85vw;margin: 0 auto;}
.footer__link > a {font-style: normal;font-weight: 400;color: #3d3d3d;font-family: Roboto,sans-serif;font-size: calc(.8vw + 9px);line-height: calc(1.2vw + 9px);padding: 0 20px;}
.footer__link > a:hover {text-decoration: none;}
.footer__contact > div {max-width: 480px; float: right}
.footer__items {width: calc(100% - 40px);max-width: 85vw;margin: 0 auto 0 auto;display: grid;grid-template-columns: 1fr 1fr}
.footer__items-item {}

@media (max-width : 1920px) {
  .header {max-width: 1552px;}
  .content__item-subItem {max-width: 1552px;}
  .benefitBanner {max-width: 1552px;}
  .footer__title {max-width: 1552px;}
  .footer__description {max-width: 1552px;}
  .footer__inn {max-width: 1552px;}
  .footer__items {max-width: 1552px;}
}

@media (max-width : 1000px) {
  .header {position: sticky;top: -1px;}
  .header__menu {display: none;}
  .header__menuMobile {display: flex;}
  .iconMenu {display: unset;}
  .iconCross {display: unset;}
  .header__menuMobile {top: 8vw;}
}

@media (max-width : 825px) {
  .gridPartnersEng {display: grid;grid-template-columns: repeat(auto-fill, 205px);}
  .gridPartnersEng__item {width: 185px;height: 105px;padding: 10px;}
}

@media (max-width : 769px) {
  #benefit > .content__item-subItem{background-position: right 11% bottom 13%;background-repeat: no-repeat;background-size: 28%;}
  .benefitBanner {flex-direction: column;}
  .benefitBanner__item:last-child {margin-left: 0;margin-top: 20px;}
  .benefitBanner__item > img {margin-bottom: calc(1.1vw + 9px);height: 12vw;}
  .marketingBanner {flex-direction: column;align-items: start;}
  .marketingBanner__right {margin-top: 20px;justify-content: center;width: 100%;}
  .marketingBanner__right-circleLeft {width: 20vw;height: 20vw;}
  .marketingBanner__right-circleCenter {width: 32.5vw;height: 32.5vw;}
  .circleBigInner {flex: 0 1 30vw;height: 30vw;}
  .marketingBanner__right-circleRight {width: 20vw;height: 20vw;}
  .icons {flex-wrap: wrap;}
  .icons__item_max-width {min-width: 33%;}
}

@media (max-width : 700px) {
  .footer {flex: 1 1 auto;padding: 20px 0;}
  .footer__title {font-size: calc(3vw + 9px);line-height: calc(3.9vw + 9px);margin-bottom: 10px;}
  .footer__description {margin: 10px 0 10px 0;width: calc(100% - 40px);}
  .footer__inn {margin: 10px 0 10px 0;width: 100%;padding: 0;}
  .footer__items {grid-template-columns: 1fr;}
  .footer__items-item:last-child {margin-bottom: 10px;border: 0px solid #fff;margin-left: 20px;}
  .footer__contact > div {float: left}
}

@media (max-width : 580px) {
  .icons__item-text {text-align: left !important;}
  #whatOffer .icons__item-text2 {text-align: unset;}
  #whatOffer .icons__item_max-width2:not(:first-child) .icons__item-text {text-align: unset;}
  #whatOffer .icons {flex-direction: column;}
  #whatOffer .icons__item {flex-direction: row;width: 100%;}
  #whatOffer .icons__item {margin: 15px 0;justify-content: start;}
  .icons__item-icon {margin-bottom: calc(-1.7vw + 9px);}
  .arrow {transform: rotate(90deg);width: 12vw;margin-left: 52px;}
  .icons__item_max-width2 {max-width: 100%;}
  .icons__item_max-width2 > .icons__item-icon {margin-bottom: 0;height: 32vw;width: 32vw;margin-right: 10px;}
  #benefit>.content__item-subItem {background-position: right 6% bottom 12%;}
  .gridPartners {display: grid;grid-template-columns: repeat(auto-fill, 112px);}
  .gridPartners__item {width: 92px;height: 59px;padding: 10px;}
  .gridPartnersEng {display: grid;grid-template-columns: repeat(auto-fill, 112px);}
  .gridPartnersEng__item {width: 92px;height: 59px;padding: 10px;}
}

@media (max-width : 500px) {
  .title {font-size: calc(2.5vw + 9px);line-height: calc(2.7vw + 9px);}
  .title2 {font-size: calc(2.5vw + 9px);line-height: calc(2.7vw + 9px);}
  .text {line-height: calc(2vw + 9px);}
  .header__choice {flex: 0 0 auto;margin-left: 0;}
  .header__choice-icon > img {margin-left: 0;}
  .header__choice-language {display: none;}
  .header__languageMobile {display: flex;}
  .header__menuMobile > a {font-size: calc(3vw + 5px);}
  .header__img {flex: 0 0 8vw;height: 8vw;margin-right: 0;}
  .content__item {height: calc(100% - 40px);padding: 20px 0;}
  .content__item .banner {flex-direction: column;}
  .content__item-subItem {width: calc(100% - 40px);padding: 0 20px;max-width: unset;}

  .banner__left {max-width: 100%;}
  .banner__left > .banner__left-button {display: none;}
  .banner__left-text {font-size: calc(1.8vw + 9px);margin-top: 20px;}
  .banner__right {flex: 1 1 255px;background-position: top 30% center;background-repeat: no-repeat;background-size: 60%;height: 100%;}
  .banner__right > .banner__left-button {display: unset;font-size: 5vw;}
  #solution .icons {flex-wrap: wrap;}
  .icons__item_max-width {min-width: 50%;}
  .icons__item_max-width > .icons__item-icon {margin-bottom: calc(1.3vw + 9px);height: 10vw;width: 10vw;}
  #whatOffer .icons {flex-direction: column;}
  #whatOffer .icons__item {flex-direction: row;width: 100%;}
  #whatOffer .icons__item {margin: 15px 0;justify-content: start;}
  .arrow {transform: rotate(90deg);width: 17vw;margin-left: 24px;}
  .icons__item_max-width2 {max-width: 100%;}
  .icons__item_max-width2 > .icons__item-icon {margin-bottom: 0;height: 32vw;width: 32vw;margin-right: 10px;}
  .gridText {grid-template-columns: 1fr;}
  .point {width: 2vw;height: 2vw;}
  .benefitBanner {width: 100%;padding: 0;}
  .benefitBanner__item-text {margin-bottom: 10px;line-height: calc(2vw + 9px);}
  .benefitBanner__item {padding: 15px;}
  .benefitBanner__item > img {margin-bottom: calc(1.1vw + 9px);height: 17vw;}
  #benefit > .content__item-subItem {background-position: center bottom 33% !important;background-repeat: no-repeat;background-size: 75%;}
  #benefit .gridText {margin-bottom: 70vw !important;}
  .marketingBanner__right {flex-direction: column;}
  .marketingBanner__right-circleLeft {width: 30vw;height: 30vw;left: 0;top: 20px;}
  .marketingBanner__right-circleCenter {width: 55.5vw;height: 55.5vw;}
  .circleBigInner {flex: 0 1 51vw;height: 51vw;}
  .marketingBanner__right-circleRight {width: 30vw;height: 30vw;right: 0;bottom: 20px;}
  .textBig {font-size: calc(3vw + 9px);line-height: calc(7vw + 9px);}
  .textSmall {font-size: calc(2.1vw + 4px);line-height: calc(2.6vw + 4px);}
}

@media (max-width : 376px) {
  #benefit > .content__item-subItem {background-position: center bottom 33% !important;}
  #benefit > .content__item-subItem.spn {background-position: center bottom 36% !important;}
}

@media (max-width : 321px) {
  #benefit > .content__item-subItem {background-position: center bottom 34% !important;}
  #benefit > .content__item-subItem.spn {background-position: center bottom 37% !important;}
}

@media (max-width : 300px) {
  #benefit > .content__item-subItem {background-position: center bottom 33% !important;}
  #benefit > .content__item-subItem.spn {background-position: center bottom 35% !important;}
}

.brandsBg__black{background-color: #000000;}
.brandsBg__green{background-color: #70B41F;}
.brandsBg__blueH{background-color: #03080a;}
.brandsBg__blueD{background-color: #4090d9;}
.brandsBg__blue{background-color: #4BA2F1;}
.brandsBg__pink{background-color: #E91E39;}
.brandsBg__orange{background-color: #FF6E00;}
.brandsBg__red{background-color: #F92414;}
.brandsBg__white{background-color: #FFFFFF;}
.brandsBg__liteBlue{background-color: #FFFFFF;}

/* doc & api */
#templateApi {margin-top:20px;padding-top:10px;}
#contactUs {margin-bottom:20px;padding-bottom:10px;}
#contactUs > div > div > a {margin-top: 20px;} /* #contactUs > div > div > a */
.apiDoc__text-prepare {font-style: normal;font-weight: 400;color: #3d3d3d;font-family: Roboto,sans-serif;font-size: calc(.8vw + 9px);line-height: calc(1.2vw + 9px);}
.apiDoc__text-a {color: #30527b !important;font-weight: 700;}
.apiDoc__text {text-align: left;margin: 0 auto;padding-top: 20px;vertical-align: top;}
.apiDoc__text > a {text-align: left;width: 100px;display: inline-block;vertical-align: top;color: black;}
.apiDoc__text > a:hover {text-decoration: underline;cursor: pointer;}
.apiDoc__text > a > div {width: 100px;vertical-align: top;text-align: center;}
.apiDoc__text > a > div > img {width:50px;height:50px;vertical-align: top;}
.apiDoc__img {width:50px;height:50px;}
.apiDoc__doc {padding-top: 5px;}
.paddingSmall{padding: 10px;}
